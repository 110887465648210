import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { fetchBlogList } from "../api";
import { AuthContext } from "../context/AuthContext";

import imageNotFound from "../assets/image/image-not-found.jpg";

const BlogPosts = () => {
  const [loading, setLoading] = useState(true);
  const [blogList, setBlogList] = useState([]);
  const { auth } = useContext(AuthContext);

  const getBlogList = async () => {
    const response = await fetchBlogList();
    // Show all blogs for admin and only published otherwise
    const blogs = auth?.admin
      ? response
      : response.filter((blog) => blog.published);
    setBlogList(blogs);
    setLoading(false);
  };

  useEffect(() => {
    getBlogList();
    return () => setLoading(true);
  }, []);

  return (
    <>
      <PageWrapper>
        <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-xs-12 ">
                <div className="pt-12 ml-lg-0 ml-md-15">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                      {loading ? (
                        <Skeleton width={200} height={26} className="mb-2" />
                      ) : (
                        <h5 className="font-size-4 font-weight-normal text-default-color">
                          <span className="heading-default-color mr-2">
                            {blogList.length}
                          </span>
                          results for{" "}
                          <span className="heading-default-color">All</span>
                        </h5>
                      )}
                    </div>
                    {auth?.admin ? (
                      <div>
                        <Link
                          to="/new-blog"
                          className="text-green font-size-3 text-uppercase font-weight-bold"
                        >
                          New Blog
                        </Link>
                      </div>
                    ) : null}
                  </div>

                  <div className="pt-6">
                    {loading ? (
                      <div className="row justify-content-center">
                        {[1, 2, 3, 4].map((index) => (
                          <div key={index} className="col-12 col-lg-6">
                            <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                              <div className="d-flex justify-content-center mb-7">
                                <Skeleton width={200} height={150} />
                              </div>
                              <h2 className="mt-n4 ml-n1">
                                <Skeleton width={200} height={42} />
                              </h2>
                              <p className="mt-4">
                                <Skeleton width={200} height={24} />
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row justify-content-left">
                        {blogList &&
                          blogList.map((blog, index) => (
                            <div key={index} className="col-12 col-lg-6">
                              <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                                <div className="d-flex align-items-center justify-content-center mb-7">
                                  <Link
                                    to={`/blog/${blog.blogId}`}
                                    state={{ id: blog.blogId }}
                                  >
                                    {blog?.cover ? (
                                      <img
                                        src={blog.cover}
                                        alt={`${blog?.blogId}`}
                                        height={150}
                                      />
                                    ) : (
                                      <img
                                        src={imageNotFound}
                                        alt="Not Found"
                                        width={150}
                                      />
                                    )}
                                  </Link>
                                </div>
                                <h2 className="mt-n4 h-84">
                                  <Link
                                    to={`/blog/${blog.blogId}`}
                                    state={{ id: blog.blogId }}
                                    className="font-size-6 text-black-2 font-weight-bold mb-4"
                                  >
                                    {blog?.title}
                                  </Link>
                                </h2>
                                <p className="font-size-3 text-gray font-weight-semibold mb-4">
                                  {new Date(
                                    blog?.created.toDate()
                                  ).toDateString()}
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default BlogPosts;
